import { publicEnv } from '../constants/publicEnv'
import { isSSR } from './ssr'

export const getEnvironment = () => {
  //TODO: This is technically incorrect, since just because we're in SSR doesn't mean we're not in an environment.
  // Can't safely/easily remove since some code looks like it makes this assumption, eg gtm

  if (isSSR) {
    return undefined
  }

  if (!!publicEnv.REACT_APP_ENVIRONMENT) {
    return publicEnv.REACT_APP_ENVIRONMENT
  }

  //TODO: Once the chart change is in all environments the logic below can be cleaned up.
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'
  ) {
    return 'local'
  }

  const href = window.location.href

  return href.includes('dev.')
    ? 'dev'
    : href.includes('qa.')
    ? 'qa'
    : href.includes('uat.')
    ? 'uat'
    : href.includes('toblerones.')
    ? 'toblerones'
    : href.includes('thesharkeys.')
    ? 'thesharkeys'
    : href.includes('jatz.')
    ? 'jatz'
    : href.includes('perftest.')
    ? 'perftest'
    : href.includes('sit.')
    ? 'sit'
    : 'production'
}

export enum Locale {
  'AU' = 'en-AU',
  'UK' = 'en-GB',
}

const localeLinks: Record<
  Locale,
  Record<'phqHome' | 'aboutUs' | 'sales' | 'contact', string>
> = {
  [Locale.UK]: {
    phqHome: 'https://get.playhq.com/',
    aboutUs: 'https://get.playhq.com/about-us',
    sales: 'https://get.playhq.com/sales',
    contact: 'https://get.playhq.com/contact',
  },
  [Locale.AU]: {
    phqHome: '/au/',
    aboutUs: '/au/about-us',
    sales: '/au/sales',
    contact: '/au/contact',
  },
}

export const getLocale = () => {
  const envLocale = process.env.REACT_APP_LOCALE as Locale

  if (
    process.env.NODE_ENV === 'development' &&
    (!envLocale || !Object.values(Locale).includes(envLocale))
  ) {
    console.error(
      `REACT_APP_LOCALE is not set or unsupported, got: ${envLocale}`,
    )
  }

  const currentLocale = envLocale || Locale.AU

  const links = localeLinks[currentLocale]

  return {
    locale: currentLocale,
    links,
    isUK: currentLocale === Locale.UK,
  }
}
